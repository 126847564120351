<template>
  <div class="wrapper">
    <div class="quote-wrapper">
      <div class="quote">He who fights with monsters should look to it that he himself does not become a monster.</div>
      <div class="author">- Friedrich Nietzsche</div>
    </div>
    <div id="tentacles"></div>
    <div class="notfound">404</div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      
    }
  },
  mounted() {
    if (document.readyState === 'complete') this.init()
    else window.addEventListener('load', this.init);
  },
  methods: {
    init() {
      let width = window.innerWidth;
      let height = window.innerHeight - 100;
      const container = document.getElementById('tentacles');
      const renderer = new Renderer(width, height);
      const scene = new Scene(width, height);
      container.appendChild(renderer.canvas);
      const update = () => {
        requestAnimationFrame(update);
        renderer.render(scene);
      };
      const resize = () => {
        width = window.innerWidth;
        height = window.innerHeight - 100;
        renderer.setSize(width, height);
        scene.setSize(width, height);
      };
      window.addEventListener('resize', resize);
      resize();
      update();
    } 
  }
}

// ——————————————————————————————————————————————————
// Random
// ——————————————————————————————————————————————————

const Random = (() => {
    const Random = Math.random;
    Random.float = (min, max) => {
        if (min == null) min = 0;
        else if (max == null) max = min, min = 0;
        return min + Random() * (max - min);
    };
    Random.int = (min, max) => Math.round(Random.float(min, max));
    Random.sign = (chance = 0.5) => Random() >= chance ? -1 : 1;
    Random.bool = (chance = 0.5) => Random() < chance;
    Random.bit = (chance = 0.5) => Random() < chance ? 0 : 1;
    Random.item = (list) => list[~~((Random()) * list.length)];
    return Random;
})();

// ——————————————————————————————————————————————————
// Tentacle
// ——————————————————————————————————————————————————

const Tentacle = (() => {
    class Tentacle {
        constructor(x = 0, y = 0, angle = Random.float(Math.PI * 2)) {
            this.x = x;
            this.y = y;
            this.angle = angle;
            this.segments = [];
            this.fillColor = '#6D832F';
            this.strokeColor = '#b6d070';
            this.lineWidth = 0.25;
            this.segmentCount = Random.int(80, 220);
            this.thickness = Random.float(10, 50);
            this.spacing = Random.float(2.0, 6.0);
            this.curl = Random.float(0.1, 0.85);
            this.step = Random.float(0.01, 0.075);
            this.length = 0;
            this.build();
        }
        build() {
            let theta = this.angle;
            let x = this.x;
            let y = this.y;
            let v = 0;
            for (let s, c, i = 0; i < this.segmentCount; i++) {
                s = Math.sin(theta);
                c = Math.cos(theta);
                this.segments.push({
                    radius: this.thickness / 2,
                    scale: 0,
                    theta,
                    // Perpendicular unit vector for rendering outlines.
                    px: -s,
                    py: c,
                    x,
                    y
                });
                x += c * this.spacing;
                y += s * this.spacing;
                v += this.step * Random.float(-1, 1);
                v *= 0.9 + this.curl * 0.1;
                theta += v;
            }
        }
        get length() {
            return this._length;
        }
        set length(value) {
            const limit = this.segments.length * value;
            const power = Math.pow(value, 0.25);
            this.segments.forEach((segment, index) => {
                segment.scale = index < limit ?
                    ((1 - (index / limit)) * power) :
                    0;
            });
            this._length = value;
        }
    }
    return Tentacle;
})();

// ——————————————————————————————————————————————————
// Scene
// ——————————————————————————————————————————————————

const Scene = (() => {
    const MAX_TENTACLES = 8;
    class Scene {
        constructor(width, height) {
            this.tentacles = [];
            this.addTentacle = this.addTentacle.bind(this);
            this.setSize(width, height);
            this.addTentacle();
        }
        addTentacle() {
            const x = this.width / 2 + Random.float(-5, 5);
            const y = this.height / 2 + Random.float(-5, 5);
            const tentacle = new Tentacle(x, y);
            const duration = Random.float(2.0, 3.0);
            this.tentacles.push(tentacle);
            Tween.to(tentacle, duration, { length: 0.99 })
                .ease(Tween.Sine.inOut)
                .done(() => {
                    const index = this.tentacles.indexOf(tentacle);
                    this.tentacles.splice(index, 1);
                    this.addTentacle();
                });
            if (this.tentacles.length < MAX_TENTACLES) {
                const delay = Random.int(100, 1000);
                setTimeout(this.addTentacle, delay);
            }
        }
        setSize(width, height) {
            this.width = width;
            this.height = height;
        }
    }
    return Scene;
})();

// ——————————————————————————————————————————————————
// Renderer
// ——————————————————————————————————————————————————

const Renderer = (() => {
    const TAU = Math.PI * 2;
    const HPI = Math.PI / 2;
    const BACKGROUND_COLOR = '#fafafa';
    const CLEAR_INTERVAL = 15.0;
    class Renderer {
        constructor(width, height) {
            this.canvas = document.createElement('canvas');
            this.context = this.canvas.getContext('2d');
            this.setSize(width, height);
            this.clearAlpha = 0.01;
            this.clear = this.clear.bind(this);
            this.clear();
        }
        clear() {
            Tween.to(this, 2, { clearAlpha: 0.08 })
                .wait(CLEAR_INTERVAL)
                .ease(Tween.Sine.out)
                .done(() => {
                    Tween.to(this, 1, { clearAlpha: 0.01 })
                        .ease(Tween.Sine.out)
                        .done(this.clear);
                });
        }
        render(scene) {
            this.context.globalAlpha = this.clearAlpha;
            this.context.fillStyle = BACKGROUND_COLOR;
            this.context.fillRect(0, 0, this.width, this.height);
            this.context.globalAlpha = 1;
            scene.tentacles.forEach(tentacle => {
                const n = Math.floor(tentacle.segments.length * tentacle.length);
                const segments = tentacle.segments;
                // eslint-disable-next-line no-unused-vars
                let a, b, sA, sB, lxA, lyA, rxA, ryA, lxB, lyB, rxB, ryB;
                for (let i = 0; i < n - 1; i++) {
                    a = segments[i];
                    b = segments[i + 1];
                    sA = a.radius * a.scale;
                    this.context.beginPath();
                    if (i === 0) {
                        this.context.arc(a.x, a.y, sA, 0, TAU);
                    } else {
                        sB = b.radius * b.scale;
                        lxA = a.x - a.px * sA;
                        lyA = a.y - a.py * sA;
                        rxA = a.x + a.px * sA;
                        ryA = a.y + a.py * sA;
                        lxB = b.x - b.px * sB;
                        lyB = b.y - b.py * sB;
                        rxB = b.x + b.px * sB;
                        ryB = b.y + b.py * sB;
                        this.context.moveTo(rxA, ryA);
                        this.context.arc(a.x, a.y, sA, a.theta + HPI, a.theta - HPI);
                        this.context.lineTo(lxB, lyB);
                        this.context.lineTo(rxB, ryB);
                        this.context.lineTo(rxA, ryA);
                    }
                    this.context.fillStyle = tentacle.fillColor;
                    this.context.strokeStyle = tentacle.strokeColor;
                    this.context.lineWidth = tentacle.lineWidth;
                    this.context.fill();
                    this.context.stroke();
                }
            });
        }
        setSize(width, height) {
            const scale = window.devicePixelRatio || 1;
            this.width = width;
            this.height = height;
            this.canvas.width = width * scale;
            this.canvas.height = height * scale;
            this.canvas.style.width = width + 'px';
            this.canvas.style.height = height + 'px';
            this.context.scale(scale, scale);
            this.context.fillStyle = BACKGROUND_COLOR;
            this.context.fillRect(0, 0, width, height);
        }
    }
    return Renderer;
})();

//------------------------------
// Tween
//------------------------------

const Tween = (() => {

  //------------------------------
  // Constants
  //------------------------------

  var PI = Math.PI;
  var acos = Math.acos;
  var pow = Math.pow;
  var cos = Math.cos;
  var sin = Math.sin;
  var raf = requestAnimationFrame;
  // var caf = cancelAnimationFrame;

  //------------------------------
  // Helpers
  //------------------------------

  function isFunction(object) {
    return Object.prototype.toString.call(object) == '[object Function]';
  }

  function trigger(callbacks, callback) {
    if (isFunction(callback)) {
      callbacks.push(callback);
    } else {
      if (callbacks.length) {
        var params = Array.prototype.slice.call(arguments, 1);
        for (var i = 0, n = callbacks.length; i < n; i++) {
          callbacks[i].apply(null, params);
        }
      }        
    }
  }

  function ease(core) {
    core.in = core;
    core.out = function(t) {
      return 1 - core(1 - t);
    };
    core.inOut = function(t) {
      return t <= 0.5 ? core(t * 2) / 2 : (2 - core(2 * (1 - t))) / 2;
    };
    return core;
  }

  function copy(target, options) {
    var result = {};
    for (var key in options) {
      result[key] = target[key];
    }
    return result;
  }

  function now() {
    return performance.now();
  }

  //------------------------------
  // Prototype
  //------------------------------

  function Tween(target, duration, options) {
    this.startTime = now();
    this.duration = duration;
    this.options = options;
    this.target = target;
    this.easing = API.Linear;
    this.onStart = [];
    this.onStep = [];
    this.onDone = [];
    this.progress = 0;
    this.paused = false;
    this.alive = true;
    this.delay = 0;
  }

  Tween.prototype = {

    wait: function(delay) {
      this.delay = delay;
      return this;
    },

    ease: function(callback) {
      this.easing = callback;
      return this;
    },

    kill: function() {
      this.alive = false;
      return this;
    },

    start: function(callback) {
      trigger(this.onStart, callback);
      return this;
    },

    pause: function() {
      if (!this.paused) {
        this.pauseTime = now();
        this.paused = true;
      }
    },

    play: function() {
      if (this.paused) {
        this.startTime += now() - this.pauseTime;
        this.paused = false;
      }
    },
    
    step: function(callback) {
      trigger(this.onStep, callback);
      return this;
    },

    done: function(callback) {
      trigger(this.onDone, callback);
      return this;
    }
  };

  //------------------------------
  // Engine
  //------------------------------

  var running = false;
  var tweens = [];
  // eslint-disable-next-line no-unused-vars
  var req;

  function update() {
    
    if (running) {

      var tween, delta, key, a, b;

      for (var i = tweens.length - 1; i >= 0; i--) {
        
        tween = tweens[i];

        if (tween.alive) {

          delta = (now() - tween.startTime) * 0.001;

          if (delta > tween.delay && !tween.paused) {
            
            if (tween.progress === 0) {
              tween.initial = copy(tween.target, tween.options); 
              trigger(tween.onStart, tween);
            }

            tween.progress = (delta - tween.delay) / tween.duration;
            tween.progress = Math.max(0.0, Math.min(tween.progress, 1.0));

            for (key in tween.options) {
              a = tween.initial[key];
              b = tween.options[key];
              tween.target[key] = a + (b - a) * tween.easing(tween.progress);
            }

            trigger(tween.onStep, tween);
          }

          if (tween.progress >= 1.0) {
            tween.alive = false;
            trigger(tween.onDone, tween);
          }  
        }

        if (!tween.alive) {
          tweens.splice(i, 1);
        }
      }

      if (tweens.length) {
        req = raf(update);
      } else {
        running = false;
      }
    }
  }

  function start() {
    if (!running) {
      req = raf(update);
      running = true;
    }
  }


  // function stop() {
  //   running = false;
  //   caf(req);
  // }

  function queue(tween) {
    tweens.push(tween);
    start();
    return tween;
  }

  //------------------------------
  // API
  //------------------------------

  var API = {

    Linear: ease(function(t) {
      return t;
    }),

    Elastic: ease(function(t) {
      return pow(2, 10 * --t) * cos(20 * t * PI * 1 / 3 );
    }),

    Bounce: ease(function(t) {
      // eslint-disable-next-line no-constant-condition
      for (var n, a = 0, b = 1; 1; a += b, b /= 2) {
        if (t >= (7 - 4 * a) / 11){
          n = -pow((11 - 6 * a - 11 * t) / 4, 2) + b * b;
          break;
        }
      }
      return n;
    }),

    Back: ease(function(t) {
      return pow(t, 2) * ((1.618 + 1) * t - 1.618);
    }),

    Sine: ease(function(t) {
      return 1 - sin((1 - t) * PI / 2);
    }),

    Circ: ease(function(t) {
      return 1 - sin(acos(t));
    }),

    Expo: ease(function(t) {
      return pow(2, 10 * (t - 1));
    }),

    Quad: ease(function(t) {
      return pow(t, 2);
    }),

    Cubic: ease(function(t) {
      return pow(t, 3);
    }),

    Quart: ease(function(t) {
      return pow(t, 4);
    }),

    Quint: ease(function(t) {
      return pow(t, 5);
    }),

    to: function(target, duration, options) {
      var tween = new Tween(target, duration, options);
      return queue(tween);
    }
  };

  return API;

})();
</script>

<style scoped lang="scss">
#tentacles {
  height: calc(100vh - 100px);
  width: 100%;
}

.quote-wrapper {
  border-radius: 0 5px 5px 0;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  font-family: "Ranga", cursive;
  position: absolute;
  bottom: 100px;
  right: 0;
  width: 550px;
  font-size: 36px;
  font-weight: bold;
  background-color:rgba(255, 255, 255, 0.7);
  padding: 20px;
  line-height: 42px;
  color: #444;

  .author {
    text-align:right;
    margin-top:20px;
    font-weight:700;
  }
}

.notfound {
  border-radius: 5px 0 0 5px;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  font-family: "Ranga", cursive;
  position: absolute;
  top: 100px;
  left: 0;
  width: 350px;
  font-size: 140px;
  font-weight: bold;
  background-color:rgba(255, 255, 255, 0.7);
  padding: 20px;
  color: #444;
  text-align:center;
}
</style>