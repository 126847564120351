import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ErrorNotFound from '../views/ErrorNotFound.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/auth/:key',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/SocialAuth.vue')
  },
  {
    path: '/admin/:key',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "login" */ '../views/admin/Admin.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "register" */ '../views/Profile.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/auth/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/auth/ResetPassword.vue')
  },
  {
    path: '/email/verify/:id/:hash',
    name: 'Email Verify',
    component: () => import(/* webpackChunkName: "emailverify" */ '../views/auth/EmailVerify.vue')
  },
  {
    path: '/maps',
    name: 'Maps',
    component: () => import(/* webpackChunkName: "maps" */ '../views/Maps.vue')
  },
  {
    path: '/foundry',
    name: 'FoundryVTT',
    component: () => import(/* webpackChunkName: "maps" */ '../views/Foundry.vue')
  },
  {
    path: '/generator',
    name: 'Generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "generator" */ '../views/GeneratorV2.vue')
  },
  {
    path: '/generator/custom',
    name: 'Generator Custom',
    component: () => import(/* webpackChunkName: "custom" */ '../views/GeneratorVariant.vue')
  },
  {
    path: '/generator/:type',
    name: 'Generator Type',
    component: () => import(/* webpackChunkName: "generatortype" */ '../views/GeneratorType.vue')
  },
  {
    path: '/generator/:type/:variant',
    name: 'Generator Variant',
    component: () => import(/* webpackChunkName: "generatorvariant" */ '../views/GeneratorVariant.vue')
  },
  {
    path: '*',
    name: '404',
    component: ErrorNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next();
})
export default router
