<template>
<span>
  <a :class="{'btn-block btn-lg': type === 'block'}" class="btn btn-danger" :href="'https://www.patreon.com/oauth2/authorize?response_type=code&client_id=' + clientId + '&redirect_uri=' + getCurrentURL">
    <img alt="Login to your account using Patreon" :class="{'patreon-icon-lg': type === 'block'}" :width="type === 'block' ? 20 : 16" :height="type === 'block' ? 20 : 16" class="patreon-icon" src="/images/patreon.png" loading="lazy"/> Login with Patreon
  </a>
</span>


</template>

<script>
export default {
  name: 'LoginPatreon',
  props: {
    type: { type:String, default: 'block' }
    },
  data: function() {
      return {
        clientId: '4L_tulgDf2c206VahkPYB7IGO0sGyUfH4CGm07qT9jNcr5gSbcEmxbafwjMlIOVp', // Prod
        // clientId: '_BrZ49TdEoO8tF_qG0JwYSGtBQzqQEowLsL2hjZ-EPKKjqTam6gHaDQEph5ZKP0H' // Dev
      }
  },
  methods: {
  },
  computed: {
    getCurrentURL: function () {
      if (window.location.hostname === 'localhost') {
        return encodeURIComponent('http://localhost:8080/generator');
      } else {
        return encodeURIComponent('https://handouts.cthulhuarchitect.com/generator');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.patreon-icon {
  margin-right:4px;
  position:relative;
  &.patreon-icon-lg {
    margin-right:6px;
    top:-2px;
  }
}
</style>
