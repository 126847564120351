<template>
  <div class="container page">
    <h2>Welcome to Cthulhu Architect's handout generator!</h2>
    <p>Empower RPG Game Masters to bring their worlds to life with our intuitive handout generator. Create immersive and professional-looking handouts for your role-playing game sessions effortlessly. From ancient tomes to cryptic letters, our tool enables GMs to easily design and customize visually stunning handouts that enhance storytelling and captivate players. Level up your game with our handout generator and unlock a new dimension of immersive storytelling for your RPG adventures.</p>

    <div class="row hero">
      <div class="col-12 col-lg-6">
        <img class="img-fluid img-cthulhu" src="/cthulhu_500.webp" fetchpriority="high" width="500" height="500" alt="Cthulhu Architect Wants You!"/>
      </div>
      <div class="col-12 col-lg-6">
        <ul>
          <li>Create handouts for your RPG</li>
          <li>100+ handouts to choose from</li>
          <li>Simple drag & drop interface</li>
          <li>New handouts added each month</li>
          <li>A huge selection of different fonts</li>
          <li><a href="https://www.patreon.com/posts/handout-foundry-68144488">FoundryVTT Module</a></li>
          <li>A lot of free handouts</li>
          <li>Full access to all handouts for patrons</li>
          <li><a href="https://discord.gg/2tgye4fWpZ" target="_blank">Join my Discord server</a> and send me your bugs, ideas and suggestions</li>
        </ul>
        <div class="text-center">
          <router-link to="/generator"><button class="btn btn-lg btn-block btn-primary">Launch Handout Generator</button></router-link>
          <LoginPatreon></LoginPatreon>
          <p class="patron-text"><a href="https://www.patreon.com/cthulhu_architect" target="_blank">Support me on Patreon</a> and get full access to all the handouts without a watermark and support the further development of the handout generator.</p>
        </div>
        <p>&nbsp;</p>

      </div>
    </div>
  </div>
</template>

<script>
import LoginPatreon from '@/components/LoginPatreon.vue'

export default {
    name: "Home",
    components: { LoginPatreon }
}
</script>

<style lang="scss" scoped>
  .page {
    padding:50px;
    font-size:20px;
    line-height:28px;
    background:#fff;
    margin-top:20px;
    margin-bottom:20px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
  }

  .img-cthulhu {
    position:relative;
    left:-25px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .btn-primary {
    margin-top:40px;
    margin-bottom:20px;
  }

  a:hover {
      text-decoration: none;
  }

  .patron-text {
    font-size:16px;
    line-height:18px;
    margin-top:15px;
    margin-bottom:0;
  }

  .discord-text {
    font-size:14px;
    line-height:16px;
  }

  .hero {
    margin-top:40px;
  }

  ul {
    font-size:20px;
    line-height:30px;
  }
</style>
