<template>
<div>
<section class="cthulhu redeyes animation">
</section>
</div>
</template>

<script>
export default {
  name: 'Cthulhu',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
:root {
  --color-green: #338855;
  --color-neon-green: #00ff1a;
  --color-scale-green: #0c1f13;
  --color-dark-scale-green: #13231a;
  --color-dark-green: #162d1f;
  --color-darkest-green: #153623;
  --duration: 1s;
}

/* Enlaces */

.cthulhu a {
  display: inline-block;
  position: relative;
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
  
}

.cthulhu a:hover {
  color: var(--color-neon-green);
  text-shadow: 0px 0px 5px var(--color-neon-green);
}

.cthulhu a:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 10%;
  bottom: 0px;
  left: 0px;
  background-color: #fff;
  transition: background 0.3s;
}

.cthulhu a:hover:after {
  background-color: var(--color-neon-green);
  box-shadow: 0px 0px 8px 3px var(--color-neon-green);
}


/* Sections */

section.cthulhu {
  margin: 0;
  position: relative;
}

section.cthulhu > * {
  font-size: 2rem;
  line-height: 2rem;
  color: #fff;
  position: relative;
}
section.cthulhu.redeyes {
  background-color: var(--color-darkest-green);
}

section.cthulhu.redeyes:after,
section.cthulhu.redeyes:before {
  --width: 20px;
  --top: 6px;
  --left: calc(50% - (var(--width) / 2));
  content: "";
  position: absolute;
  top: var(--top);
  left: var(--left);
  width: var(--width);
  height: calc((var(--width) / 3 ) * 2);
  background-color: red;
  border-bottom-left-radius: calc(var(--width) - (var(--width) / 10));
  border-bottom-right-radius: calc((var(--width) / 3) + (var(--width) / 10));
  border-top-right-radius: calc((var(--width) / 3) + (var(--width) / 10));
  transform: rotate(10deg);
  box-shadow: 0px 0px 14px #b70000;
  transition: height 0.3s, top 0.3s;
}

section.cthulhu.redeyes:before {
  left: calc(var(--left) + var(--width) + (var(--width) / 6));
  border-bottom-left-radius: calc((var(--width) / 3) + (var(--width) / 10));
  border-bottom-right-radius: calc(var(--width) - (var(--width) / 10));
  border-top-right-radius: 0px;
  border-top-left-radius: calc((var(--width) / 3) + (var(--width) / 10));
  transform: rotate(-10deg);
}

section.cthulhu.redeyes.hover:hover:after,
section.cthulhu.redeyes.hover:hover:before {
  height: 0px;
  top: calc(var(--top) + ((var(--width) / 3 ) * 2));
}

section.cthulhu.redeyes.animation:after,
section.cthulhu.redeyes.animation:before {
  animation-name: redeyes;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: reverse;
}

@keyframes redeyes {
  0% {
    height: 0px;
  }
  2% {
    height: calc((var(--width) / 3 ) * 2);
  }
  10% {
    height: calc((var(--width) / 3 ) * 2);
  }
  12% {
    height: 0px;
  }
  22% {
    height: 0px;
  }
  25% {
    height: calc((var(--width) / 3 ) * 2);
  }
  45% {
    height: calc((var(--width) / 3 ) * 2);
  }
  55% {
    height: 0px;
  }
  70% {
    height: calc((var(--width) / 3 ) * 2);
  }
  80% {
    height: 0px;
  }
  90% {
    height: calc((var(--width) / 3 ) * 2);
  }
  100% {
    height: 0px;
  }
}
</style>
