<template>
  <div id="app">
    <b-alert class="new-version" show variant="warning">
    <img alt="When the stars are right" src="/elder-sign.svg" height="24" width="24" loading="lazy" />
    Have you ever dreamed of a newer and better version of the Handout Generator? When the stars are right, you will hear the call...
    <img alt="When the stars are right" src="/elder-sign.svg" height="24" width="24" loading="lazy" />
    </b-alert>
    <b-navbar toggleable="lg" type="dark" variant="info" v-if="!isFoundry">
      <b-navbar-brand class="logo" href="/"><img alt="Cthulhu Architect Handout Generator" src="./assets/logo-small.webp" height="40" width="28" loading="lazy"/></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :exact="true" to="/">About</b-nav-item>
          <b-nav-item to="/generator">Handout Generator</b-nav-item>
          <b-nav-item to="/maps">Maps</b-nav-item>
          <b-nav-item to="/foundry">FoundryVTT Module</b-nav-item>
          <b-nav-item href="https://www.patreon.com/cthulhu_architect" target="_blank">Become a patron</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="user && user.is_admin" :class="{ 'router-link-active': $route.path.indexOf('/admin') === 0 }" to="/admin/users">Admin</b-nav-item>

          <!-- <b-nav-item v-if="!user" to="/login">Login</b-nav-item>
          <b-nav-item v-if="!user" to="/register">Register</b-nav-item> -->

          <b-nav-item-dropdown v-if="user" text="My Account" right>
            <b-dropdown-item to="/profile">Profile</b-dropdown-item>
            <div role="separator" class="dropdown-divider"></div>
            <b-dropdown-item @click="logout()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-alert v-if="user && !user.email_verified_at" show variant="warning">Your email address has not been verified yet. If you haven't received the verification email, <a href="javascript:void(0);" @click="resendVerificationEmail">click here to resend it</a></b-alert>

    <div :class="{ 'page-container': !isFoundry, 'page-container-full': isFoundry }">
      <router-view :key="$route.fullPath" />
    </div>

    <footer class="footer" v-if="!isFoundry">
      <div class="container-fluid">
        <div class="row">
          <div class="col copyright">
            &copy; 2021-2024 Cthulhu Architect
          </div>
          <div class="col">
            <Cthulhu />
          </div>
          <div class="col social">
            <a href="https://patreon.com/cthulhu_architect" aria-label="Patreon" target="_blank"><i class="fab fa-patreon"></i></a>
            <a href="https://www.youtube.com/channel/UC_9wb81iW3YKcyq6GvinuaQ" aria-label="Youtube" target="_blank"><i class="fab fa-youtube"></i></a>
            <a href="https://discord.gg/2tgye4fWpZ" aria-label="Discord" target="_blank"><i class="fab fa-discord"></i></a>
            <a href="https://www.pinterest.com/cthulhu_architect/cthulhu-architect-modern-maps/" aria-label="Pinterest" target="_blank"><i class="fab fa-pinterest"></i></a>
            <a href="https://www.instagram.com/cthulhu_architect/" aria-label="Instagram" target="_blank"><i class="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Cthulhu from '@/components/Cthulhu.vue';

export default {
  name: 'APP',
  components: { Cthulhu },
  data: function() {
    return {
      clientId: '4L_tulgDf2c206VahkPYB7IGO0sGyUfH4CGm07qT9jNcr5gSbcEmxbafwjMlIOVp', // Prod
      // clientId: '_BrZ49TdEoO8tF_qG0JwYSGtBQzqQEowLsL2hjZ-EPKKjqTam6gHaDQEph5ZKP0H' // Dev
      isFoundry: false,
      user: null
    };
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    const isFoundry = urlParams.get('foundry');
    const key = urlParams.get('key');

    if (isFoundry === 'enable') {
      window.localStorage.setItem('pDTTL', null);
      window.localStorage.setItem('pD', null);
      window.localStorage.setItem('isFoundry', null);

      this.isFoundry = true;

      window.localStorage.setItem('isFoundry', true);

      if (key === 'GDtUJagW4s5FWPDPmcVz') {
        window.localStorage.setItem('pD', JSON.stringify({ id: '1234', attributes: { full_name: 'Cthulhu Architect' } }));

        const now = new Date();
        window.localStorage.setItem('pDTTL', now.getTime() + 14400000);
      }

      // window.location.href = '/generator';
    } else {
      window.localStorage.removeItem('isFoundry');
    }

    if (window.localStorage.getItem('isFoundry')) {
      this.isFoundry = true;
    }

    this.$http.get('/user')
      .then(response => {
        this.user = response.data;
      });

    setTimeout(() => {
      if (window.plausible) {
        window.plausible('UniqueVisits', { props: { platform: this.isFoundry ? 'foundry' : 'web', isPatron: this.isPatron } });
      }
    }, 5000);
  },
  methods: {
    logout() {
      this.$http.post('/logout')
      .then(() => {
        this.user = null;
        window.location.href = '/';
      });
    },
    resendVerificationEmail() {
      this.$http.post('/email/verification-notification')
      .then(() => {
        this.$toast.success('Verification email sent.');
      });
    }
  },
  computed: {
    getCurrentURL: function() {
      if (window.location.hostname === 'localhost') {
        return encodeURIComponent('http://localhost:8080/generator');
      } else {
        return encodeURIComponent('https://handouts.cthulhuarchitect.com/generator');
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles";

.new-version {
  margin-bottom:0;
  border:0;
  border-radius:0;
  text-align:center;
  font-weight:600;
  font-size:20px!important;

  img {
    position:relative;
    top:-3px;
    margin-right:10px;
    margin-left:10px;
  }
}
</style>
