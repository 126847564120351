import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import InputColorPicker from "vue-native-color-picker";
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import router from './router'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'

Vue.use(Toast, {});
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(require('vue-moment'));

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.cthulhuarchitect.com';

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log(error);
  if (error.response.data.message && error.response.status !== 401) {
    Vue.$toast.error(error.response.data.message);
  }

  return Promise.reject(error);
});

Vue.prototype.$http = axios;

Vue.config.productionTip = false

Vue.use(InputColorPicker);
Vue.component('vue-draggable-resizable', VueDraggableResizable);

Vue.mixin({
  data: function () {
    return {
      get isPatron() {
        const pDTTL = window.localStorage.getItem('pDTTL');

        if (pDTTL) {
          const now = new Date();
          if (now.getTime() > pDTTL) { // If it need to be invalidated
            window.localStorage.setItem('pDTTL', null);
            window.localStorage.setItem('pD', null);
            return false;
          }
        } else {
          return false;
        }

        const data = JSON.parse(window.localStorage.getItem('pD'));

        if (data) {
          if (data.id &&
            data.attributes.full_name) {
            return true;
          } else {
            return false;
          }
        }

        return false;
      }
    }
  },
  methods: {}
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
